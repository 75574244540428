import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import { MasterIndex } from "verdiapi";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier } from "verditypes";

import { ALL_DATA_TYPES, DISPLAY_DATETIME_FORMAT, SOURCE_TYPE, TIMESTAMP_COLUMN } from "../constants";
import { ColumnGrouping, Columns, PerDataKeyValues, Rows } from "../types";
import { generateDatabase, loadData } from "./db";
import { cascadingSort } from "./sort";
/**
 * Handles generating DB, loading data from the DB, and creating CSV data objects
 */
export async function createCSVData({
    sources,
    dataTypes,
    startDate,
    endDate,
    columnGroupKey,
}: {
    sources: { id: string; type: SOURCE_TYPE }[];
    dataTypes: DataTypeSpecifier[];
    startDate: Date;
    endDate: Date;
    columnGroupKey: ColumnGrouping;
}) {
    // Generate the database and load data
    const db = generateDatabase({ sources, dataTypes });
    db.compressedRequests = true;
    db.debugTiming = true;

    await loadData(db, startDate, endDate);

    // Create the data keys
    const { zoneKeys, deviceKeys, perDataKeyValues, validDataKeys, dataKeys, otherSourceKeys } = createDataKeys(db);

    // Create the data rows
    const { rows, dataKeysToKeep } = createDataRows({
        db,
        perDataKeyValues,
        validDataKeys,
        dataKeys,
    });

    const zoneAndDeviceKeys = [...zoneKeys, ...deviceKeys];
    // Create the column order
    const { columns } = createColumns({
        zoneAndDeviceKeys,
        columnGroupKey,
        perDataKeyValues,
        dataKeysToKeep,
        otherSourceKeys,
    });

    return {
        rows,
        columns,
    };
}

/**
 * Create DataKeys
 */
function createDataKeys(db: HistoricalDataBase) {
    // Get the data keys from the database (unique column strings for source/dataType pairs)
    let dataKeys = Object.keys(db.dataKeyMetadataLookup);

    console.info(
        `DataExport: Parsing linked list with ${dataKeys.length} keys and ${db.data.value.length} data points`,
    );

    // Create an empty set to store the valid keys
    const validDataKeys = new Set() as Set<string>;

    // Create empty objects to store the source id, source type, source name, zone name, and data type for each key
    const sourceIDsPerDataKey: Record<string, string> = {};
    const sourceTypesPerDataKey: Record<string, string> = {};
    const sourceNamesPerDataKey: Record<string, string> = {};
    const zoneNamesPerDataKey: Record<string, string> = {};
    const dataTypesPerDataKeys: Record<string, string> = {};

    // Create empty arrays to store the zone, device, and other keys
    const zoneKeys: string[] = [];
    const deviceKeys: string[] = [];
    const otherSourceKeys: string[] = [];

    // Iterate through the data keys
    dataKeys.forEach((k) => {
        // Get the source and zone info for the data key
        const sourceID = db.dataKeyMetadataLookup[k].source;
        let sourceName;
        let zoneName;

        // Set the source ID
        sourceIDsPerDataKey[k] = sourceID;

        // Add the key to the appropriate array and set the name and zone name

        // Zone source
        if (MasterIndex.zone.byID[sourceID]) {
            // Set the source and zone names
            sourceName = MasterIndex.zone.byID[sourceID].name;
            zoneName = sourceName;

            // Set the source type
            sourceTypesPerDataKey[k] = "zone";

            // Add the key to the zone rows
            zoneKeys.push(k);

            // Irrigation device source
        } else if (MasterIndex.irrigationDevice.byID[sourceID]) {
            // "moisture" dataType is invalid for irrigation devices
            if (db.dataKeyMetadataLookup[k].dataType === "moisture") {
                console.warn(`DataExport: Invalid column. dataType of "moisture" found for irrigation device: ${k}`);
                return;
            }

            // Add the key to the device rows
            deviceKeys.push(k);

            // Set the source name
            sourceName = MasterIndex.irrigationDevice.byID[sourceID].name;

            // Set the source type
            sourceTypesPerDataKey[k] = "device";

            // Set the zone name if there are zones attached
            if (MasterIndex.irrigationDevice.byID[sourceID].zonesByValve) {
                // Combine the names of all the zones attached to the device (filter out duplicates caused by single-valve devices)
                zoneName = [
                    ...new Set(
                        MasterIndex.irrigationDevice.byID[sourceID].zonesByValve
                            .filter((z) => z !== null && z !== undefined)
                            .map((z) => z.name),
                    ),
                ].join(" | ");
            }

            // Block valve source
        } else if (MasterIndex.blockValve.byID[sourceID]) {
            // "moisture" dataType is invalid for block valves
            if (db.dataKeyMetadataLookup[k].dataType === "moisture") {
                console.warn(`DataExport: Invalid column. dataType of "moisture" found for block valve: ${k}`);
                return;
            }

            // Add the key to the device rows
            deviceKeys.push(k);

            // Set the name
            sourceName = MasterIndex.blockValve.byID[sourceID].name;

            // Set the source type
            sourceTypesPerDataKey[k] = "device";

            try {
                // Set the zone name if there are zones attached
                if (MasterIndex.blockValve.byID[sourceID].connectedZones) {
                    // Combine the names of all the zones attached to the device
                    zoneName = [
                        ...new Set(
                            MasterIndex.blockValve.byID[sourceID].connectedZones
                                .filter((z) => z !== null && z !== undefined)
                                .map((z) => z.name),
                        ),
                    ].join(" | ");
                }
            } catch (e) {
                console.warn(`Failed to find name for connected zone for device ${sourceID}`);
                zoneName = "Unknown Zone";
            }

            // Third party device source
        } else if (MasterIndex.thirdPartyDevice.byID[sourceID]) {
            // Add the key to the device rows
            deviceKeys.push(k);

            // Set the name
            sourceName = MasterIndex.thirdPartyDevice.byID[sourceID].name;

            // Set the source type
            sourceTypesPerDataKey[k] = "thirdPartyDevice";

            // Set the zone name if there are zones attached
            if (MasterIndex.thirdPartyDevice.byID[sourceID].connectedZones) {
                // Combine the names of all the zones attached to the device
                zoneName = MasterIndex.thirdPartyDevice.byID[sourceID].connectedZones
                    .filter((z) => z !== null && z !== undefined)
                    .map((z) => z.name)
                    .join(" | ");
            }

            // Other source with metadata available
        } else if (db.dataKeyMetadataLookup[k]) {
            // Add the key to the other rows
            otherSourceKeys.push(k);

            // Set the name
            sourceName = db.dataKeyMetadataLookup[k].source;

            // Set the zone name if there are zones attached
            if (db.dataKeyMetadataLookup[k].relevantZones) {
                // Combine the names of all the zones attached to the device
                zoneName = db.dataKeyMetadataLookup[k].relevantZones
                    ?.map((zid) => MasterIndex.zone.byID[zid].name)
                    .join(" | ");
            }

            // Other source
        } else if (db.dataKeys[k]?.relevantZones) {
            // Add the key to the other rows
            otherSourceKeys.push(k);

            // Set the name and zone name
            sourceName = "unknown name";
            zoneName = "unknown zone";
        } else {
            // Add the key to the other rows
            otherSourceKeys.push(k);

            // Set the name and zone name
            sourceName = "unknown name";
            zoneName = "unknown zone";
        }

        // Key is valid at this point
        validDataKeys.add(k);

        // Add the source name, zone name, and data type to the appropriate objects
        zoneNamesPerDataKey[k] = zoneName as string;
        sourceNamesPerDataKey[k] = sourceName as string;
        dataTypesPerDataKeys[k] = ALL_DATA_TYPES[db.dataKeyMetadataLookup[k].dataType].label;
    });

    // Add the valid keys to an array
    dataKeys = [...validDataKeys] as string[];

    // Return compiled keys
    return {
        zoneKeys,
        deviceKeys,
        perDataKeyValues: {
            sourceIDsPerDataKey,
            sourceTypesPerDataKey,
            sourceNamesPerDataKey,
            dataTypesPerDataKeys,
            zoneNamesPerDataKey,
        } as PerDataKeyValues,
        validDataKeys,
        otherSourceKeys,
        dataKeys,
    };
}

/**
 * Create the data rows for the CSV
 */
function createDataRows({
    db,
    perDataKeyValues,
    validDataKeys,
    dataKeys,
}: {
    db: HistoricalDataBase;
    perDataKeyValues: PerDataKeyValues;
    validDataKeys: Set<string>;
    dataKeys: string[];
}) {
    const {
        sourceIDsPerDataKey,
        sourceTypesPerDataKey,
        sourceNamesPerDataKey,
        dataTypesPerDataKeys,
        zoneNamesPerDataKey,
    } = perDataKeyValues;
    // Add the row labels (in the first column along with the ISO datetimes)
    sourceIDsPerDataKey[TIMESTAMP_COLUMN] = "Source ID";
    sourceTypesPerDataKey[TIMESTAMP_COLUMN] = "Source type";
    sourceNamesPerDataKey[TIMESTAMP_COLUMN] = "Source name";
    dataTypesPerDataKeys[TIMESTAMP_COLUMN] = "Data type";
    zoneNamesPerDataKey[TIMESTAMP_COLUMN] = "Zone name";

    // Create an empty array to store the data rows
    const dataRows: Rows = [];

    // Create a set to store the keys with non-zero values
    const keysWithNonZeroValues = new Set();

    // Create an empty object to store the most recent value for each key
    const mostRecentValuesForKeys: Record<string, any> = {};

    // Iterate through the data points in the database
    db.data.value.forEach((dataPoint) => {
        // Create a copy of the data point's data
        const dataRow: Record<string, any> = { ...dataPoint.data };

        // Get datetime from the data point
        const dateTime = dataPoint.date;

        // Iterate through the data keys
        dataKeys.forEach((k) => {
            // If the key exists in the data point's data, its value is now the most recent value for the key
            if (dataRow[k] !== null && dataRow[k] !== undefined) {
                // If the key's value is non-zero, add it to the set of keys with non-zero values
                if (dataRow[k] !== 0) {
                    keysWithNonZeroValues.add(k);
                }

                // Set the most recent value for the key
                mostRecentValuesForKeys[k] = dataRow[k];

                // If the key does not exist in the data point's data, set its value to the most recent value for the key
            } else if (mostRecentValuesForKeys[k] !== null && mostRecentValuesForKeys[k] !== undefined) {
                dataRow[k] = mostRecentValuesForKeys[k];
            } else {
                dataRow[k] = null;
            }
        });

        // Add the local date and time to the data
        dataRow[TIMESTAMP_COLUMN] = getTimeString(dateTime, DISPLAY_DATETIME_FORMAT);

        // Add the data row to the array of data rows
        dataRows.push(dataRow);
    });

    // Keep the keys with non-zero values and the valid keys
    const dataKeysToKeep = new Set([...validDataKeys, ...keysWithNonZeroValues, TIMESTAMP_COLUMN]) as Set<string>;

    // Remove dataRows with keys not in the set of keys to keep
    dataRows.forEach((dataRow) => {
        Object.keys(dataRow).forEach((rowKey) => {
            if (!dataKeysToKeep.has(rowKey)) {
                delete dataRow[rowKey];
            }
        });
    });

    // Log the keys
    console.info("DataExport: All keys: ", dataKeys);
    console.info("DataExport: Keys with non-zero values: ", [...keysWithNonZeroValues]);
    console.info("DataExport: Valid keys: ", [...validDataKeys]);

    // Combine all the rows together
    const rows: Rows = [
        sourceIDsPerDataKey,
        sourceTypesPerDataKey,
        sourceNamesPerDataKey,
        dataTypesPerDataKeys,
        zoneNamesPerDataKey,
        ...dataRows,
    ];

    return { rows, dataKeysToKeep };
}

/**
 * Create the column order for the CSV
 */
function createColumns({
    zoneAndDeviceKeys,
    columnGroupKey,
    perDataKeyValues,
    dataKeysToKeep,
    otherSourceKeys,
}: {
    zoneAndDeviceKeys: string[];
    columnGroupKey: ColumnGrouping;
    perDataKeyValues: PerDataKeyValues;
    dataKeysToKeep: Set<string>;
    otherSourceKeys: string[];
}) {
    const sorted = cascadingSort({
        primarySortKey: columnGroupKey,
        keys: zoneAndDeviceKeys,
        perDataKeyValues,
    });

    // Create an array to store the column headers
    let columns: Columns = [TIMESTAMP_COLUMN];

    // Add the merged keys and other keys to the column headers
    columns.push(...sorted, ...otherSourceKeys);

    // Remove columns with keys not in the set of keys to keep
    columns = columns.filter((columnKey) => dataKeysToKeep.has(columnKey));

    return { columns };
}
