import * as turf from "@turf/turf";
import { FeatureCollection, Point } from "geojson";

import { getMetersPerPixel } from "../constants";
import type MapEntityBase from "../mapEntities/MapEntityBase";
import { getIconScaling, getScaledIconSize } from "./sizing";
import { Cluster, ClusterMapItem, ZoomLayeredMapState } from "./types";

interface CalculateClustersParams {
    data: FeatureCollection<Point>;
    maxDistance: number;
    options?: {
        units?: turf.Units | undefined; // for maxDistance, defaults to km
        minPoints?: number | undefined;
        mutate?: boolean | undefined;
    };
}
// Clusters map entities
export const calculateClusters = ({ data, maxDistance = 20, options }: CalculateClustersParams) => {
    const clustered = turf.clustersDbscan(data, maxDistance, options);
    return clustered;
};

// Creates a feature collection of points from map entities
export const createPointsFeatureCollection = (mapEntities: MapEntityBase[]) => {
    const points = mapEntities.map((mapEntity) =>
        turf.point([mapEntity.long, mapEntity.lat], {
            id: mapEntity.id,
        }),
    );
    return turf.featureCollection(points);
};

interface GetMaxClusteringDistanceOptions {
    zoom: number;
    mapCenter: { lat: number; lng: number };
    bufferInPixels?: number;
}
// Gets the max clustering distance in meters for a given zoom level and map center
export const getMaxClusteringDistance = ({ zoom, mapCenter, bufferInPixels = 25 }: GetMaxClusteringDistanceOptions) => {
    const metersPerPixel = getMetersPerPixel(zoom, mapCenter);
    const iconScaling = getIconScaling(zoom);
    const scaledIconSize = getScaledIconSize(iconScaling);
    return metersPerPixel * (scaledIconSize + bufferInPixels);
};

export const findClusterCenter = (cluster: Cluster) => {
    const centroid = turf.centroid(cluster);
    return centroid.geometry.coordinates;
};

/**
 * Get the zoom levels at which a cluster breaks up into individual devices
 * ie. none of its contained children are part of any further grouped
 * - returns undefined if this level does not exist, or any of its children continue to be grouped
 */
interface CalculateClusterBreakupZoomParams {
    zoom: number;
    clusterMapItem: ClusterMapItem;
    maxZoom: number;
    clusterSignatures: { [zoom: number]: { [clusterMapItemId: string]: string } };
    memberToClusterMap: { [zoom: number]: { [memberId: string]: string } };
}
const calculateClusterBreakupZoom = ({
    zoom,
    clusterMapItem,
    maxZoom,
    clusterSignatures,
    memberToClusterMap,
}: CalculateClusterBreakupZoomParams) => {
    const clusterMemberIds = new Set(clusterMapItem.cluster.features.map((f) => f.properties?.id));

    for (let nextZoom = zoom + 1; nextZoom <= maxZoom; nextZoom++) {
        const someChildFoundInAnotherCluster = Array.from(clusterMemberIds).some((memberId) => {
            const nextClusterId = memberToClusterMap[nextZoom][memberId];
            return (
                nextClusterId &&
                clusterSignatures[nextZoom][nextClusterId] !== clusterSignatures[zoom][clusterMapItem.id]
            );
        });

        if (someChildFoundInAnotherCluster) {
            return undefined;
        }

        const allChildrenUnclustered = Array.from(clusterMemberIds).every(
            (memberId) => !memberToClusterMap[nextZoom][memberId],
        );
        if (allChildrenUnclustered) {
            return nextZoom;
        }
    }

    return undefined;
};

/**
 * Generates a unique signature for a cluster based on its members and location
 */
const generateClusterMapItemSignature = (clusterMapItem: ClusterMapItem) => {
    const memberIds = clusterMapItem.cluster.features
        .map((f) => f.properties?.id)
        .sort()
        .join(",");
    const location = `${clusterMapItem.lat},${clusterMapItem.long}`;
    return `${memberIds}|${location}`;
};

/**
 * Get the zoom level at which a cluster breaks up into individual devices
 */
export const getBreakupZoomLevels = (zoomLayeredMapState: ZoomLayeredMapState, maxZoom: number) => {
    // Optimize by caching the cluster signatures and member to cluster map
    const clusterSignatures: { [zoom: number]: { [clusterMapItemId: string]: string } } = {};
    const memberToClusterMap: { [zoom: number]: { [memberId: string]: string } } = {};

    for (let zoom = 0; zoom <= maxZoom; zoom++) {
        clusterSignatures[zoom] = {};
        memberToClusterMap[zoom] = {};
        zoomLayeredMapState[zoom].clusterMapItems.forEach((clusterMapItem) => {
            const signature = generateClusterMapItemSignature(clusterMapItem);
            clusterSignatures[zoom][clusterMapItem.id] = signature;
            clusterMapItem.cluster.features.forEach((feature) => {
                if (feature.properties?.id) {
                    memberToClusterMap[zoom][feature.properties.id] = clusterMapItem.id;
                }
            });
        });
    }

    // Iterate over each zoom level and for each cluster calculate the breakup zoom level
    const breakupZoomLevels: { [zoom: number]: { [clusterMapItemId: string]: number | undefined } } = {};
    for (let zoom = 0; zoom <= maxZoom; zoom++) {
        breakupZoomLevels[zoom] = {};

        zoomLayeredMapState[zoom].clusterMapItems.forEach((clusterMapItem) => {
            breakupZoomLevels[zoom][clusterMapItem.id] = calculateClusterBreakupZoom({
                zoom,
                clusterMapItem,
                maxZoom,
                clusterSignatures,
                memberToClusterMap,
            });
        });
    }

    return breakupZoomLevels;
};
