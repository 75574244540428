import * as turf from "@turf/turf";

import { GenerateUID } from "../../../utils/HelperFunctions";
import type MapEntityBase from "../mapEntities/MapEntityBase";
import { findClusterCenter } from "./clustering";
import {
    Cluster,
    type ClusterMapItem,
    CoordinateUniqueMapItems,
    ENTITY_TYPE,
    MapItem,
    ZoomLayeredMapState,
} from "./types";

/**
 * Categories of models that are included in the icon layer
 */
const CategoriesInIconLayer = ["blockValve", "irrigationDevice", "thirdPartyDevice", "SensoterraMoisture"];
export const mapEntityBelongsInIconLayer = ({ mapEntity }: { mapEntity: MapEntityBase }) =>
    Boolean(mapEntity?.model && CategoriesInIconLayer.includes(mapEntity?.model.category));

/**
 * Creates the cluster map items from the clustered FeatureCollection
 */
export const createClusterMapItems = (
    clusteredFeatures: Cluster,
): {
    clusterMapItems: ClusterMapItem[];
    clusterKeys: { [key: string]: number | undefined };
} => {
    const clusterMapItems: ClusterMapItem[] = [];
    // Maps member's ids to cluster ids if clustered
    const clusterKeys: { [key: string]: number | undefined } = {};

    turf.clusterEach(clusteredFeatures, "cluster", (cluster, clusterKey) => {
        // All features in cluster have same dbscna type, so just use first one
        const firstFeature = cluster.features[0];

        // Ignore noise points since they are individual devices (i.e. not part of a group)
        if (firstFeature.properties?.dbscan === "noise") return;

        // Find the centroid of cluster's members
        const [long, lat] = findClusterCenter(cluster);

        // Mark device ids as part of a cluster
        turf.featureEach(cluster, (feature) => {
            if (feature?.properties?.id && clusterKey) {
                clusterKeys[feature.properties.id] = clusterKey;
            }
        });

        clusterMapItems.push({
            id: GenerateUID(),
            clusterKey,
            type: ENTITY_TYPE.CLUSTER,
            lat,
            long,
            cluster: cluster,
        } as ClusterMapItem);
    });

    return { clusterMapItems, clusterKeys };
};

/**
 * Encode lat/long coordinates into a string to use as a unique key
 * not guaranteed to be unique as coodinates are rounded, but should be good enough
 */
export const generateCoordinateUniqueKey = (item: MapItem) => `${item.lat},${item.long}`;

/**
 * Returns the unique items across all zoom levels
 * - unique items are those that share the same exact lat/long coordinates across all zoom levels
 */
export const getCoordinateUniqueMapItems = (zoomLayeredMapState: ZoomLayeredMapState): CoordinateUniqueMapItems => {
    const uniqueItems = new Map<string, MapItem>();
    Object.values(zoomLayeredMapState).forEach(({ clusterMapItems, deviceMapItems }) => {
        [...clusterMapItems, ...deviceMapItems].forEach((item) => {
            const key = generateCoordinateUniqueKey(item);
            if (!uniqueItems.has(key)) {
                uniqueItems.set(key, item);
            }
        });
    });
    return uniqueItems;
};
