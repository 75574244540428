import { DataTypeSpecifierEnum } from "verditypes";

export interface DataType {
    label: string;
    category: string;
}

// Create an enum-like mapping for data type categories
export const DATA_TYPE_CATEGORY = {
    NDVI: "NDVI",
    SOIL_SENSOR: "Soil Sensor",
    SOIL_MOISTURE: "Soil Moisture",
    IRRIGATION_CONTROL: "Irrigation Control",
    WATER_FLOW: "Water Flow",
    IRRIGATION_PLANNING: "Irrigation Planning",
    ENVIRONMENTAL: "Environmental",
    PRESSURE_SENSOR: "Pressure Sensor",
    ADMIN_ONLY: "Admin Only",
} as const;

// Create a mapping for user data types with their labels and categories
export const USER_DATA_TYPES: Record<string, DataType> = {
    avgNDVI: { label: "Mean NDVI", category: DATA_TYPE_CATEGORY.NDVI },
    draginoEC: { label: "Electrical conductivity (Dragino)", category: DATA_TYPE_CATEGORY.SOIL_SENSOR },
    moisture_watermark: { label: "Soil moisture (Watermark)", category: DATA_TYPE_CATEGORY.SOIL_MOISTURE },
    moisture_irrometer: { label: "Soil moisture (Irrometer)", category: DATA_TYPE_CATEGORY.SOIL_MOISTURE },
    moisture_vol: { label: "Volumetric soil moisture", category: DATA_TYPE_CATEGORY.SOIL_MOISTURE },
    moisture: { label: "Soil moisture", category: DATA_TYPE_CATEGORY.SOIL_MOISTURE },
    ndviReading: { label: "NDVI", category: DATA_TYPE_CATEGORY.NDVI },
    ndviVariance: { label: "NDVI Variance", category: DATA_TYPE_CATEGORY.NDVI },
    valveOpenPercent: { label: "Valve open percentage", category: DATA_TYPE_CATEGORY.IRRIGATION_CONTROL },
    valveState: { label: "Valve state", category: DATA_TYPE_CATEGORY.IRRIGATION_CONTROL },
    v1flowmeter: { label: "Flow meter (Verdi)", category: DATA_TYPE_CATEGORY.WATER_FLOW },
    zoneIrrigationScheduled: { label: "Scheduled zone irrigations", category: DATA_TYPE_CATEGORY.IRRIGATION_PLANNING },
    temperature: { label: "Ambient temperature", category: DATA_TYPE_CATEGORY.ENVIRONMENTAL },
    v1transducer: { label: "Pressure transducer (Verdi)", category: DATA_TYPE_CATEGORY.PRESSURE_SENSOR },
} as const;

// Create a mapping for all data types with those not in USER_DATA_TYPES marked as admin-only
export const ALL_DATA_TYPES: Record<string, DataType> = Object.fromEntries(
    Object.values(DataTypeSpecifierEnum).map((dataType) => {
        if (USER_DATA_TYPES[dataType as keyof typeof USER_DATA_TYPES]) {
            return [dataType, USER_DATA_TYPES[dataType as keyof typeof USER_DATA_TYPES]];
        }
        return [dataType, { label: dataType, category: DATA_TYPE_CATEGORY.ADMIN_ONLY }];
    }),
);

// Create a mapping from each category to an array of data type keys that belong to that category
// eslint-disable-next-line no-unused-vars
export const categoryToDataTypeKeys = Object.entries(ALL_DATA_TYPES).reduce(
    (acc: Record<string, string[]>, [key, value]) => {
        const { category } = value as { category: string };
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(key);
        return acc;
    },
    {},
);

export const FILENAME_DATE_FORMAT = "yyyy-MM-dd";
export const FILENAME_DATETIME_FORMAT = "yyyy-MM-dd_HH-mm-ss";
export const DISPLAY_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const TIMESTAMP_COLUMN = "Timestamp (local time)";

export enum SOURCE_TYPE {
    ACCOUNT = "account",
    FIELD = "field",
    ZONE = "zone",
    DEVICE = "device",
}

export const SourceTypeLabels: Record<SOURCE_TYPE, string> = {
    [SOURCE_TYPE.ACCOUNT]: "Account",
    [SOURCE_TYPE.FIELD]: "Field",
    [SOURCE_TYPE.ZONE]: "Zone",
    [SOURCE_TYPE.DEVICE]: "Device",
} as const;

export interface SourceIdOption {
    value: string;
    label: string;
}
