import L from "leaflet";

import { AttemptOnDelay } from "../../../utils/HelperFunctions";

/**
 * @type {{i : divIcon, pos: [number, number], resolve: function, reject: function}[]}
 */
export const markersOnMap: L.Marker[] = [];

/**
 * Performs the logic to add a marker to the map
 */
interface AddMapEntityIconToMapParams {
    mapComponent: L.Map;
    uid: string;
    lat: number;
    long: number;
    options?: L.MarkerOptions;
}
export async function addMarkerToMap({ mapComponent, uid, lat, long, options }: AddMapEntityIconToMapParams) {
    if (mapComponent) {
        const icon = L.divIcon({
            className: `custom-div-icon DEV_MARKER_${uid}`,
            html: "<div> </div>",
            iconSize: [1, 1],
            iconAnchor: [1, 1],
        });

        const Options = {
            icon: icon,
            ...options,
        };
        const LeafletMarker = L.marker([lat, long], Options).addTo(mapComponent);
        markersOnMap.push(LeafletMarker);
        const MarkerElement = await AttemptOnDelay(50 + Math.floor(Math.random() * 50), 100, () => {
            const Candidate = document.getElementsByClassName(`DEV_MARKER_${uid}`);
            if (Candidate) {
                if (Candidate.length < 1) {
                    return false;
                }
                if (Candidate instanceof Element) {
                    return Candidate;
                }
                if (Candidate instanceof Array) {
                    return Candidate[0];
                }
                if (Candidate) {
                    return Candidate[0];
                }
                return false;
            }
            return false;
        })
            .then((v) => {
                if (v) {
                    return v;
                }
                throw new Error("Unable to load map component");
            })
            .catch((v) => {
                throw v;
            });

        return {
            leafletMarker: LeafletMarker,
            domElement: MarkerElement,
            map: mapComponent,
        };
    }
    throw new Error("Map Component not loaded, queued for when it is loaded");
}
