// noinspection JSPotentiallyInvalidConstructorUsage

import { BufferedAction } from "gardenspadejs/dist/general";
import L from "leaflet";
import { EventHandler, SessionHandler } from "verdiapi";

import { MUItheme } from "../../styles/ColourPalette";
import GlobalOptions from "../../utils/GlobalOptions";
import { AttemptOnDelay, GenerateUID } from "../../utils/HelperFunctions";
// eslint-disable-next-line import/no-cycle
import { markersOnMap } from "./iconManager/map";
import { mapEntityBelongsInIconLayer } from "./iconManager/mapItems";

// eslint-disable-next-line import/no-mutable-exports
export let MapComponent;
let curLocationMarker;
let mapPromiseResolver;
export const MapLoadedPromise = new Promise((resolve, reject) => {
    mapPromiseResolver = resolve;
});
export const OnMapDefined = new EventHandler({ map: undefined });
export function setCurLocationMarker(v) {
    curLocationMarker = v;
    curLocationMarker.CurrentlyOnMap = false;
    curLocationMarker.renderOnMap();
}

/**
 * @type {{poly : polygon, className: string, resolve: function, reject: function}[]}
 */
const PolygonsOnMap = [];

const Overlays = [];

/**
 * @type {{i : divIcon, pos: [number, number], resolve: function, reject: function}[]}
 */
const MapEntitiesPendingAddition = new Set();

export async function addMapEntityPolygonToMap(mapEntity, vertices, options) {
    if (MapComponent) {
        if (options === undefined) {
            options = {};
        }
        // eslint-disable-next-line new-cap
        const poly = new L.polygon(vertices, options);

        poly.options.smoothFactor = 0;
        poly.options.fillOpacity = 0.2;
        poly.options.color = MUItheme.palette.primary.main;
        poly.options.className = GenerateUID(mapEntity.uid);
        poly.fill = true;
        // poly.on("click", (e) => {
        //     FocusContext.onInteraction(e, mapEntity);
        // });
        const leafletPolygon = poly.addTo(MapComponent);
        PolygonsOnMap.push(leafletPolygon);
        return {
            leafletPolygon: leafletPolygon,
            map: MapComponent,
        };
    }
    MapEntitiesPendingAddition.add(mapEntity);
    throw new Error("Map Component not loaded, queued for when it is loaded");
}

export async function AddIconToMap(UID, lat, long, options, MapMarkerReturner = {}) {
    if (!MapComponent) {
        throw new Error("Map object does not yet exist");
    }
    const icon = L.divIcon({
        className: `custom-div-icon DEV_MARKER_${UID}`,
        html: "<div> </div>",
        iconSize: [1, 1],
        iconAnchor: [1, 1],
    });

    const Options = {
        icon: icon,
        ...options,
    };
    const temp = L.marker([lat, long], Options).addTo(MapComponent);
    MapMarkerReturner.MapMarker = temp;
    markersOnMap.push(temp);

    return AttemptOnDelay(50 + Math.floor(Math.random() * 50), 100, () => {
        const Candidate = document.getElementsByClassName(`DEV_MARKER_${UID}`);

        if (Candidate) {
            if (Candidate.length < 1) {
                return false;
            }
            if (Candidate instanceof Element) {
                return Candidate;
            }
            if (Candidate instanceof Array) {
                return Candidate[0];
            }
            if (Candidate) {
                return Candidate[0];
            }
            return false;
        }
        return false;
    })
        .then((v) => {
            if (v) {
                return v;
            }
            throw new Error("Unable to load map component");
        })
        .catch((v) => {
            throw v;
        });
}

export async function AddNDVIOverlayToMap(MapLayer, ImgOverlay) {
    if (!MapComponent) {
        throw new Error("Map object does not yet exist");
    }
    MapComponent.addLayer(MapLayer);
    Overlays.push(ImgOverlay.addTo(MapComponent));
    MapComponent.removeLayer(MapLayer);
}

export async function MakeNDVIMapVisible(MapLayer) {
    MapComponent.addLayer(MapLayer);
}

document.body.addEventListener(
    "keydown",
    (e) => {
        if (MapComponent && e.ctrlKey) {
            if (e.key === "=") {
                MapComponent.zoomIn(1);
                e.preventDefault();
            } else if (e.key === "-") {
                MapComponent.zoomOut(1);
                e.preventDefault();
            }
        }
    },
    { passive: false },
);

// manage state for the starting position. This is ignored after initial map load
let startingPositionOfMap;
export function setStartingPositionOfMap(lat, long) {
    startingPositionOfMap = [lat, long];
}
export function getStartingPositionOfMap() {
    return startingPositionOfMap ?? SessionHandler.defaultPosition;
}
/**
 * @param map
 */
export function DefineMapComponent(map, allMapEntities) {
    try {
        setupHatching();
    } catch (e) {
        console.warn(e);
    }

    MapComponent = map;

    /**
     * Render non-device map entities (overlays, aoi, zones, etc) 
     * which are not included in <IconLayer> and not already on map
     * 
     * TODO remove blocking by feature flag
     */
    if (GlobalOptions.devFeatureFlags.enableNewIcons) {
        Array.from(allMapEntities)
            .filter(
            (mapEntity) => !mapEntityBelongsInIconLayer({ mapEntity }) && !mapEntity.renderState.onMap,
        )
        .forEach((mapEntity) => {
            mapEntity.renderState.onMap = true;
                mapEntity.renderOnMap();
            });
    } else {
        Array.from(allMapEntities).forEach((me) => {
            me.renderState.onMap = false;
            me.renderOnMap();
        });
    }

    if (curLocationMarker) {
        curLocationMarker.CurrentlyOnMap = false;
        curLocationMarker.renderOnMap();
    }
    MapComponent.on("move", () => {
        // requestAnimationFrame(() => {
        //     OnMapViewMove.trigger(e);
        // });
        // setTimeout(() => {
        //     SingleMarker.BufferedRefreshSubdivisionsTask.AttemptExecute();
        // }, 10);
    });
    let lastPXPerMeter = 1000;
    const SetMapClass = () => {
        const zoom = MapComponent.getZoom();
        let currentZoomClass = "zoom-level__world";
        if (zoom > 20.5) {
            // more than 20
            currentZoomClass = "zoom-level__device";
        } else if (zoom > 19) {
            // 19 to 20
            currentZoomClass = "zoom-level__zone";
        } else if (zoom > 18) {
            // 18 to 19
            currentZoomClass = "zoom-level__field";
        } else if (zoom > 16) {
            // 16 to 18
            currentZoomClass = "zoom-level__farm";
        } else if (zoom > 12.5) {
            // 12.5 to 18
            currentZoomClass = "zoom-level__region";
        } else {
            currentZoomClass = "zoom-level__world";
        }
        const domElement = document.getElementById("map").getElementsByClassName("leaflet-marker-pane")[0];

        // get px per meter
        const centerLatLng = map.getCenter(); // get map center
        const pointC = map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
        const pointX = [pointC.x + 1, pointC.y]; // add one pixel to x
        // convert containerpoints to latlng's
        const latLngC = map.containerPointToLatLng(pointC);
        const latLngX = map.containerPointToLatLng(pointX);
        const distanceX = latLngC.distanceTo(latLngX); // calculate distance between c and x (latitude)
        const newPxPerMeter = 1 / distanceX.toString();
        if (Math.abs(newPxPerMeter - lastPXPerMeter) / lastPXPerMeter > 0.3) {
            lastPXPerMeter = newPxPerMeter;
            domElement.style.setProperty("--pxPerMeter", `${newPxPerMeter}px`);
        }

        const classes = Array.from(domElement.classList);
        if (classes.includes(currentZoomClass)) {
            return;
        }
        classes.forEach((c) => {
            if (c.includes("zoom-level") && c !== currentZoomClass) {
                domElement.classList.remove(c);
            }
        });
        domElement.classList.add(currentZoomClass);
    };
    const setMapClassBufferedAction = new BufferedAction(
        () => {
            requestAnimationFrame(() => {
                SetMapClass();
            });
        },
        250,
        false,
        true,
    );

    MapComponent.on("zoom", () => {
        setMapClassBufferedAction.trigger();
    });
    MapComponent.on("zoomstart", () => {
        setMapClassBufferedAction.trigger();
    });
    OnMapDefined.trigger({ map: map });
    mapPromiseResolver(map);
    setTimeout(() => {
        console.info("Map component: ", MapComponent);
        const overlayPangeSVG = MapComponent._panes.overlayPane.getElementsByTagName("svg")[0];
        if (overlayPangeSVG) {
            let newElement = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
            newElement.id = "focusedZonePattern";
            newElement.setAttribute("x", 0);
            newElement.setAttribute("y", 0);
            newElement.setAttribute("width", 30);
            newElement.setAttribute("height", 30);
            newElement.setAttribute("patternUnits", "userSpaceOnUse");
            newElement.setAttribute("stroke-width", "3px");
            newElement.setAttribute("stroke-linecap", "square");
            // newElement.innerHTML =
            //     '<rect x="0" y="0" width="30" height="30" fill="#444444"/>\n' +
            //     '<circle mask="url(#fade)" fill="white" cx="7" cy="7" r="5" />\n' +
            //     '    <circle mask="url(#fade)" fill="white"  cx="22" cy="22" r="5" />';
            newElement.innerHTML =
                '<rect x="0" y="0" width="30" height="30" fill="#444444"/>\n' +
                ' <line mask="url(#fade)" x1="0" y1="0" x2="30" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="10" y1="0" x2="40" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="20" y1="0" x2="50" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-10" y1="0" x2="20" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-30" y1="0" x2="0" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="30" y1="0" x2="60" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-20" y1="0" x2="10" y2="30" stroke="white"/>';
            overlayPangeSVG.appendChild(newElement);

            let newMaskElement = document.createElementNS("http://www.w3.org/2000/svg", "mask");
            newMaskElement.id = "focusedZoneMask";
            newMaskElement.setAttribute("x", 0);
            newMaskElement.setAttribute("y", 0);
            newMaskElement.setAttribute("width", 30);
            newMaskElement.setAttribute("height", 30);
            newMaskElement.setAttribute("patternUnits", "userSpaceOnUse");
            newMaskElement.innerHTML =
                '<rect x="-10000" y="-10000" width="1000000" height="10000000" fill="url(#focusedZonePattern)" />';
            overlayPangeSVG.appendChild(newMaskElement);

            newElement = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
            newElement.id = "selectedZonePattern";
            newElement.setAttribute("x", 0);
            newElement.setAttribute("y", 0);
            newElement.setAttribute("width", 30);
            newElement.setAttribute("height", 30);
            newElement.setAttribute("patternUnits", "userSpaceOnUse");
            newElement.setAttribute("stroke-width", "1.5px");
            newElement.setAttribute("stroke-linecap", "square");
            // newElement.innerHTML =
            //     '<rect x="0" y="0" width="30" height="30" fill="#444444"/>\n' +
            //     '<circle mask="url(#fade)" fill="white" cx="7" cy="7" r="5" />\n' +
            //     '    <circle mask="url(#fade)" fill="white"  cx="22" cy="22" r="5" />';
            newElement.innerHTML =
                '<rect x="0" y="0" width="30" height="30" fill="#444444"/>\n' +
                ' <line mask="url(#fade)" x1="0" y1="0" x2="30" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="10" y1="0" x2="40" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="20" y1="0" x2="50" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-10" y1="0" x2="20" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-30" y1="0" x2="0" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="30" y1="0" x2="60" y2="30" stroke="white"/>\n' +
                '    <line mask="url(#fade)" x1="-20" y1="0" x2="10" y2="30" stroke="white"/>';
            overlayPangeSVG.appendChild(newElement);

            newMaskElement = document.createElementNS("http://www.w3.org/2000/svg", "mask");
            newMaskElement.id = "selectedZoneMask";
            newMaskElement.setAttribute("x", 0);
            newMaskElement.setAttribute("y", 0);
            newMaskElement.setAttribute("width", 30);
            newMaskElement.setAttribute("height", 30);
            newMaskElement.setAttribute("patternUnits", "userSpaceOnUse");
            newMaskElement.innerHTML =
                '<rect x="-10000" y="-10000" width="1000000" height="10000000" fill="url(#selectedZonePattern)" />';
            overlayPangeSVG.appendChild(newMaskElement);
        }
        setMapClassBufferedAction.trigger();
    });
}

/**
 *
 * @type {Object.<string, Device>}
 */

function setupHatching() {
    if (L.Canvas) {
        L.Canvas.include({
            _fillStroke: function fillStroke(ctx, layer) {
                try {
                    const bounds = layer._rawPxBounds;
                    if (!bounds) {
                        return;
                    }
                    const size = bounds.getSize();
                    const options = layer.options;

                    if (options.fill) {
                        ctx.globalAlpha = options.fillOpacity;
                        ctx.fillStyle = options.fillColor || options.color;
                        ctx.fill(options.fillRule || "evenodd");
                    }

                    if (options.stroke && options.weight !== 0) {
                        if (ctx.setLineDash) {
                            ctx.setLineDash((layer.options && layer.options._dashArray) || []);
                        }
                        ctx.globalAlpha = options.opacity;
                        ctx.lineWidth = options.weight;
                        ctx.strokeStyle = options.color;
                        ctx.lineCap = options.lineCap;
                        ctx.lineJoin = options.lineJoin;
                        ctx.stroke();
                        if (options.imgId) {
                            const img = document.getElementById(options.imgId);
                            ctx.save(); // so we can remove the clipping
                            ctx.clip();
                            ctx.fillStyle = ctx.createPattern(img, "repeat");
                            ctx.globalAlpha = options.patternOpacity;
                            ctx.fillRect(bounds.min.x, bounds.min.y, size.x, size.y);
                            ctx.restore();
                        }
                    }
                } catch (e) {
                    console.warn(e);
                }
            },
        });
    }
}
try {
    setupHatching();
} catch (e) {
    console.warn(e);
}
