import { FeatureCollection, Point } from "geojson";

import type DeviceMapEntityV2 from "../mapEntities/DeviceMapEntityV2";
import type MapEntityBase from "../mapEntities/MapEntityBase";

/**
 * Types of cluster results from the DBSCAN algorithm
 */
type Dbscan = "core" | "edge" | "noise";

/**
 * Cluster type (from Turf.js, re-exported here for convenience)
 */
export type Cluster = FeatureCollection<
    Point,
    {
        [name: string]: any;
    } & {
        dbscan?: Dbscan;
        cluster?: number;
    }
>;

/**
 * Types of map items
 */
export enum ENTITY_TYPE {
    DEVICE = "device",
    CLUSTER = "cluster",
    OTHER = "other",
}
/**
 * Cluster map item
 */
export interface ClusterMapItem {
    id: string;
    type: ENTITY_TYPE.CLUSTER;
    clusterKey: number | undefined;
    lat: number;
    long: number;
    cluster: Cluster;
    domElement: HTMLElement;
    breakupZoom: number | undefined;
}

/**
 * Device map item
 */
export interface DeviceMapItem {
    id: string;
    lat: number;
    clusterKey: number | undefined;
    long: number;
    type: ENTITY_TYPE.DEVICE;
    mapEntity: MapEntityBase | DeviceMapEntityV2;
    domElement: HTMLElement;
}

/**
 * Map item - represents an item rendered on the map
 */
export type MapItem = ClusterMapItem | DeviceMapItem;

/**
 * Represents the map state for a given zoom level
 */
export interface MapState {
    clusterMapItems: ClusterMapItem[];
    deviceMapItems: DeviceMapItem[];
    clusterKeys: { [key: string]: number | undefined };
}

/**
 * Represents the map state for all zoom levels
 */
export interface ZoomLayeredMapState {
    [key: number]: {
        clusterMapItems: ClusterMapItem[];
        deviceMapItems: DeviceMapItem[];
        clusterKeys: { [key: string]: number | undefined };
    };
}

/**
 * A set of lat/long coordinates to map items. Keys of form `${item.lat},${item.long}`
 */
export type CoordinateUniqueMapItems = Map<string, MapItem>;
