import { iconMapZoomScaling, iconSizes } from "../../../components/icons/MapIcons/DeviceIcon/constants";
import GlobalOptions from "../../../utils/GlobalOptions";
import { getMapBreakpoint } from "../constants";

// Scales the icon size based on the user's icon size preference
export const getScaledIconSize = (iconScaling: number) => getBaseSize() * iconScaling;

// Gets current icon scaling factor
export const getIconScaling = (zoom: number) => {
    const currentZoomBreakpoint = getMapBreakpoint(zoom);
    return iconMapZoomScaling[currentZoomBreakpoint];
};

// Gets the base size based on the user's icon size preference
export const getBaseSize = () => iconSizes[GlobalOptions.userIconSizePreference];
