import { MasterIndex } from "verdiapi";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier } from "verditypes";
import { dayMs, minuteMs } from "verditypes/dist/timeConstants";

import { SOURCE_TYPE, USER_DATA_TYPES } from "../constants";
/**
 *
 * @param {String} sourceType
 * @param {String} sourceID
 * @param {Array} dataTypes
 *
 * @returns {HistoricalDataBase}
 */
interface GenerateDatabaseParams {
    sources: { id: string; type: SOURCE_TYPE }[];
    dataTypes?: DataTypeSpecifier[];
}

export function generateDatabase({ sources, dataTypes }: GenerateDatabaseParams) {
    // Define array to store the sources to get data for
    let sourceIDs: string[] | undefined;

    // Check if any selected source is an account
    const isAccountSelected = sources.some((source) => source.type === SOURCE_TYPE.ACCOUNT);

    // If account is NOT selected, include only the selected sources and their sub-sources
    if (!isAccountSelected) {
        sources.forEach((source) => {
            switch (source.type) {
                case SOURCE_TYPE.FIELD: {
                    // Add all the field's zones to sources
                    const fieldZoneIDs = MasterIndex.aoi.byID[source.id].zonesInArea.map((z) => z.id);
                    sourceIDs = [...(sourceIDs || []), ...fieldZoneIDs];

                    // Add all the field's devices to sources
                    const fieldDeviceIDs = fieldZoneIDs.flatMap((zoneID) =>
                        MasterIndex.zone.byID[zoneID].devices.map((d) => d.id),
                    );
                    sourceIDs = [...(sourceIDs || []), ...fieldDeviceIDs];

                    break;
                }
                case SOURCE_TYPE.ZONE: {
                    // Add the zone to sources
                    sourceIDs = [...(sourceIDs || []), source.id];

                    // Add all the zone's devices to sources
                    const zoneDeviceIDs = MasterIndex.zone.byID[source.id].devices.map((d) => d.id);
                    sourceIDs = [...(sourceIDs || []), ...zoneDeviceIDs];

                    break;
                }
                case SOURCE_TYPE.DEVICE: {
                    // Add the device to sources
                    sourceIDs = [...(sourceIDs || []), source.id];
                    break;
                }
                default:
                    break;
            }
        });
    }

    // Get the specified list of data types or, if unspecified, all user data types
    const dataTypesToGet = dataTypes || (USER_DATA_TYPES as unknown as DataTypeSpecifier[]);

    // Create the database with the specified sources, data types, and relevant zones
    // @ts-ignore
    const database = new HistoricalDataBase(sourceIDs as any, dataTypesToGet, minuteMs * 3, {
        worryAboutPosition: false,
        worryAboutRelevantZones: true,
    });

    // Return the database
    return database;
}

/**
 * Loads data from the db for the given time range
 */
export async function loadData(db: HistoricalDataBase, startDate: Date, endDate: Date) {
    // Start database cursor at start time
    let cursor = startDate;

    // Download data in 60 day chunks
    while (cursor.valueOf() < endDate.valueOf()) {
        // Move cursor forward 60 days or to the end time, whichever is smaller
        cursor = new Date(
            Math.min(
                cursor.valueOf() + dayMs * 60, // 60 days
                endDate.valueOf(),
            ),
        );

        console.info(`DataExport: Getting data from ${startDate.toString()} to ${cursor.toString()}`);

        // Get the data from the database (will ignore already downloaded data)
        // eslint-disable-next-line no-await-in-loop
        await db.getData(startDate, cursor);
    }

    console.info(`DataExport: Data has been loaded`);
}
